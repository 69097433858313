import { CurrencyDollarIcon } from "@heroicons/vue/20/solid";

export const unlockDatapoints = {
  order: 1,
  name: "Unlock datapoints",
  subcategory: "Unlock & access datapoints",
  icon: CurrencyDollarIcon,
  iconColor: "text-indigo-700",
  markdown: `# Unlock datapoints

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="dollar" /%}{% inlineRouterLink %}**Subscribed users**{% /inlineRouterLink %} and users with a {% inlineRouterLink %}**positive credit balance**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Tower Hunt gives you the freedom to unlock the specific datapoints that you need. Datapoints can be unlocked in batches (ex. an entire property survey, an individual property, an individual deal) or individually (ex. a single price, square footage, or involved company). Read on to learn how this works.

  {% callout type="tip" %}
  **Tip:** New to Tower Hunt? Find out {% inlineRouterLink articleId="what-is-a-datapoint?" %}what datapoints are{% /inlineRouterLink %}, {% inlineRouterLink articleId="create-a-datapoint" %}how to create them{% /inlineRouterLink %}, and {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}when they cost money{% /inlineRouterLink %}.
  {% /callout %}

  ## What to expect

  When you unlock a datapoint, Tower Hunt records a new {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="When do you pay" %}usage increment{% /inlineRouterLink %} to administer the {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go approach{% /inlineRouterLink %}.

  ## Slide to unlock

  To unlock datapoints, slide the unlocker from left to right until it reaches the end. You can test this out below:

  {% testableUnlocker /%}

  ## Individual datapoints

  When you are viewing an individual datapoint, the {% inlineRouterLink articleId="datapoint-interactions" %}action menu{% /inlineRouterLink %} offers a grading breakdown to support the {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="How much does it cost" %}pricing multiplier{% /inlineRouterLink %}:

  ![Datapoint pricing summary screenshot](https://assets.towerhunt.com/site/individualDatapointUnlock0.png)

  When you're ready to access the datapoint, just slide to unlock.

  ## Datapoint bundles

  Many datapoints connect to related downstream datapoints (ex. properties, investments, floor areas). When you are viewing a datapoint like this, you are presented with the option to unlock various bundles of downstream datapoints. These bundles are grouped into categories that are intended to make it easy to unlock just the data you need so that you can control costs.

  {% tablessContainer %}
  1. Click the {% inlineAppIcon iconName="unlock" /%}**Unlock button** to display a list of datapoint bundles.
  2. Slide to unlock the desired bundle.
  {% inlineVideo videoSource="https://assets.towerhunt.com/site/unlockDatapointBundle0.mp4" marginStyles="my-4" /%}
  {% /tablessContainer %}

  If you want more fine-grained control, just explore the individual datapoints within a "parent" datapoint. This is usually done via the {% inlineRouterLink articleId="details-tab" %}Details tab{% /inlineRouterLink %} and tabs within the {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %}.

  {% callout type="tip" %}
  **Tip:** You can always start with a small bundle and then add to your collection of datapoints as needed. This is one of the benefits of our {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" %}pay-as-you-go approach{% /inlineRouterLink %}.
  {% /callout %}`,
};
